.hovered-item {
    background-color: #e6f7ff;
    cursor: pointer;
}

.urlText {
    color: black;
    transition: color 0.3s;
}

.urlText:hover {
    color: rgb(45, 122, 210);
}